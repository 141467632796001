<template>
  <!-- Notifications -->
  <div class="">
    <!-- Header -->
    <h1>
      Notifications
    </h1>
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in notifications"
        :key="notification.id"
        :href="notification.data[userType].router ? $router.resolve({ name: notification.data[userType].router.name, params: { action: notification.data[userType].router.params.action }, query: { id: notification.data[userType].router.query.id } }).href : $router.resolve({ name: calendarRoute }).href"
        target="_blank"
      >
        <b-media>
          <template #aside>
            <b-avatar
              :src="['Form Update Request', 'Caregiver Shift Cancellation', 'Pending Confirmation for Open Shift'].includes(notification['data'][userType]['title'])
                ?
                  (notification['caregiver_picture'] ? `${url}upload/${notification.caregiver_id}/${notification['caregiver_picture']}` : null)
                :
                  (['Form Update Approved', 'Form Update Denied', 'Request for Open Shift Approved'].includes(notification['data'][userType]['title'])
                    ?
                      null
                    :
                      (notification['client_picture'] ? `${url}upload/clients/${notification['client_picture']}` : null)
                  )"
              :text="['Form Update Approved', 'Form Update Denied', 'Request for Open Shift Approved'].includes(notification['data'][userType]['title'])
                ?
                  'AD'
                :
                  null"
              size="32"
              variant="primary"
            >
              <feather-icon
                v-if="!notification['data'][userType]['title'].includes('Form')"
                icon="CalendarIcon"
                size="18"
              />
              <feather-icon
                v-if="notification['data'][userType]['title'].includes('Form')"
                icon="FileTextIcon"
                size="18"
              />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span
              class="font-weight-bolder"
              :class="notification.status === 1 ? 'text-primary' : 'text-dark'"
            >
              {{ notification['data'][userType]['title'] }}
            </span>
          </p>
          <div class="d-flex">

            <!-- eslint-disable-next-line vue/no-v-html -->
            <small class="notification-text text-dark"> <span v-html="notification['data'][userType]['subtitle'].replace(/(<([^>]+)>)/gi, '')" /> </small>

            <small class="notification-text ml-auto"> <span style="color: #b9b9c3;">{{ moment(notification.timestamp).format('YYYY-MM-DD hh:mm A') }} </span></small>

          </div>
          <!-- <small class="notification-text"><span v-html="notification['data'][userType]['subtitle']" /></small> -->
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import store from '@/store'
import {
  // eslint-disable-next-line no-unused-vars
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

import moment from 'moment'

// import axios from '@axios'
import Vue from 'vue'
// eslint-disable-next-line no-unused-vars
import { ref, computed, watch } from '@vue/composition-api'

export default {
  components: {
    // BNavItemDropdown,
    // BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    // BButton,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { role } = JSON.parse(localStorage.userData)

    const userType = role === 'agent' ? 'caregiver' : role

    const notifications = ref(computed(() => store.state.appConfig.notifications))
    const calendarRoute = ref(computed(() => {
      if (userType === 'admin') return 'apps-calendar'
      if (userType === 'caregiver') return 'shift-calendar'
      return 'clientcalendar'
    }))
    // const notifications = ref([])
    // // GET ALL NOTIFICATIONS
    // axios
    //   .post('https://coc-dev.motivit.com/api/calendar/fetchNotifications', {
    //     param: {},
    //   },
    //   {
    //     headers: {
    //       'Content-type': 'application/json',
    //     },
    //   })
    //   .then(response => {
    //     notifications.value = response.data.response.data
    //     console.log(notifications.value)
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   })
    /* eslint-disable global-require */
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    return {
      notifications,
      url: Vue.prototype.$mainUrl,
      perfectScrollbarSettings,
      userType,
      calendarRoute,
    }
  },
  methods: {
    moment() {
      return moment()
    },
  },
}
</script>

<style>
h1{
  margin: auto;
}
</style>
<style scoped>
[dir] .media-list .media{
  border-bottom: 1px solid #dad5d5;
}
[dir] p {
    margin-bottom: 0.5rem;
}
</style>
