var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('h1',[_vm._v(" Notifications ")]),_c('vue-perfect-scrollbar',{staticClass:"scrollable-container media-list scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings}},_vm._l((_vm.notifications),function(notification){return _c('b-link',{key:notification.id,attrs:{"href":notification.data[_vm.userType].router ? _vm.$router.resolve({ name: notification.data[_vm.userType].router.name, params: { action: notification.data[_vm.userType].router.params.action }, query: { id: notification.data[_vm.userType].router.query.id } }).href : _vm.$router.resolve({ name: _vm.calendarRoute }).href,"target":"_blank"}},[_c('b-media',{scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"src":['Form Update Request', 'Caregiver Shift Cancellation', 'Pending Confirmation for Open Shift'].includes(notification['data'][_vm.userType]['title'])
              ?
                (notification['caregiver_picture'] ? (_vm.url + "upload/" + (notification.caregiver_id) + "/" + (notification['caregiver_picture'])) : null)
              :
                (['Form Update Approved', 'Form Update Denied', 'Request for Open Shift Approved'].includes(notification['data'][_vm.userType]['title'])
                  ?
                    null
                  :
                    (notification['client_picture'] ? (_vm.url + "upload/clients/" + (notification['client_picture'])) : null)
                ),"text":['Form Update Approved', 'Form Update Denied', 'Request for Open Shift Approved'].includes(notification['data'][_vm.userType]['title'])
              ?
                'AD'
              :
                null,"size":"32","variant":"primary"}},[(!notification['data'][_vm.userType]['title'].includes('Form'))?_c('feather-icon',{attrs:{"icon":"CalendarIcon","size":"18"}}):_vm._e(),(notification['data'][_vm.userType]['title'].includes('Form'))?_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"18"}}):_vm._e()],1)]},proxy:true}],null,true)},[_c('p',{staticClass:"media-heading"},[_c('span',{staticClass:"font-weight-bolder",class:notification.status === 1 ? 'text-primary' : 'text-dark'},[_vm._v(" "+_vm._s(notification['data'][_vm.userType]['title'])+" ")])]),_c('div',{staticClass:"d-flex"},[_c('small',{staticClass:"notification-text text-dark"},[_c('span',{domProps:{"innerHTML":_vm._s(notification['data'][_vm.userType]['subtitle'].replace(/(<([^>]+)>)/gi, ''))}})]),_c('small',{staticClass:"notification-text ml-auto"},[_c('span',{staticStyle:{"color":"#b9b9c3"}},[_vm._v(_vm._s(_vm.moment(notification.timestamp).format('YYYY-MM-DD hh:mm A'))+" ")])])])])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }