import { render, staticRenderFns } from "./Notifications.vue?vue&type=template&id=3a4172a8&scoped=true&"
import script from "./Notifications.vue?vue&type=script&lang=js&"
export * from "./Notifications.vue?vue&type=script&lang=js&"
import style0 from "./Notifications.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Notifications.vue?vue&type=style&index=1&id=3a4172a8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a4172a8",
  null
  
)

export default component.exports